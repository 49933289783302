import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import withLocation from '../components/withLocation';
// import Scroll from '../components/Scroll';
import headingimg from '../assets/images/headingimg.png';
import config from '../../config';
import Footer from '../components/Footer';
// import { OutboundLink } from "gatsby-plugin-google-gtag"

const IndexPage = ({ search, location }) => {
  const { share } = search;
  //   const { createRedirect } = actions
  const decodedUrl = share ? decodeURI(share) : '';
  //   if (decodedUrl !== ""){
  //     createRedirect({ fromPath: location, toPath: decodedUrl, isPermanent: false, redirectInBrowser: true });
  //   }

  return (
    <>
      {share ? (
        <>
          <Helmet>
            <meta http-equiv="refresh" content={`0;url=${decodedUrl}`} />
          </Helmet>
          <Layout>
            <header id="header">
              <div className="content">
                <div className="image-header">
                  <img src={headingimg} alt={config.heading} />
                  {/* <h1>www.ddns.eus</h1> */}
                </div>

                <p>{config.subHeading}</p>
                <h2>Good!!</h2>
                <p>
                  Share download is in progress.
                  <br />
                  If share is not downloaded automatically,{' '}
                  <a href={decodedUrl}>click here</a>.
                </p>
              </div>
            </header>

            <Footer />
          </Layout>
        </>
      ) : (
        <>
          <Layout>
            <header id="header">
              <div className="content">
                <div className="image-header">
                  <img src={headingimg} alt={config.heading} />
                  {/* <h1>www.ddns.eus</h1> */}
                </div>

                <p>{config.subHeading}</p>
                <h2>404 - Page not found</h2>
                <p>Not a valid URL</p>
              </div>
            </header>

            <Footer />
          </Layout>
        </>
      )}
    </>
  );
};

export default withLocation(IndexPage);
